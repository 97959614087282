import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyB3zYHS6TdFZCmhLZ9WtBOxmCKIOgGfYSg",
  authDomain: "watch-faces-community.firebaseapp.com",
  projectId: "watch-faces-community",
  storageBucket: "watch-faces-community.appspot.com",
  messagingSenderId: "668500214424",
  appId: "1:668500214424:web:d121c6fc52f5d0a41606ed",
  measurementId: "G-6B21GJBCD9"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;